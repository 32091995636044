import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from 'src/environments/environment';


import { ICustomLoginRequest, IForgetLoginRequest, IResetLoginRequest } from '../services/models/custom.login.request';
import { ICustomLoginSuccessResponse } from '../services/models/custom.login.success.response';

@Injectable({
    providedIn: 'root'
})
export class AuthApiService {

    constructor(private http: HttpClient) { }

    requestLogin(payload: ICustomLoginRequest): Observable<ICustomLoginSuccessResponse> {
        return this.http.post<any>(
            `${environment.authService}/v1/auth/login`, payload);
    }

    requestLogout(payload: {refreshToken:string}): Observable<void> {
        return this.http.post<any>(
            `${environment.authService}/v1/auth/logout`, payload);
    }

    sendVerificationCode(phoneNumber:string): Observable<{expires_in:number}> {
        return this.http.get<any>(
            `${environment.authService}/v1/auth/sms/verification-code?phoneNumber=${phoneNumber}`);
    }

    refreshToken(payload: {refreshToken:string}): Observable<ICustomLoginSuccessResponse> {
        return this.http.put<any>(
            `${environment.authService}/v1/auth/refresh_token`, payload);
    }
    resetPassword(payload: IResetLoginRequest){
        return this.http.put<any>(
            `${environment.authService}/v1/auth/reset-password`, payload);
        }
    getPassword(payload : IForgetLoginRequest){
        return this.http.put<any>(
            `${environment.authService}/v1/auth/forgot-password`, payload);
        }
}