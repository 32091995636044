import {
  HttpEvent, HttpHandler, HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { combineLatest, from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth.service';




@Injectable()
export class AuthHttpBearerInterceptor implements HttpInterceptor {

  authorizationHeaderName = 'Authorization';
  bearerPrefix = 'Bearer';
  constructor(private authService: AuthService) {
    console.log("register AuthHttpBearerInterceptor");
  }

  /**
   * @deprecated
   * Checks if the url is excluded from having the Bearer Authorization
   * header added.
   *
   * @param req http request from @angular http module.
   * @param excludedUrlRegex contains the url pattern and the http methods,
   * excluded from adding the bearer at the Http Request.
   */
  private isUrlExcluded(
    { method, url }: HttpRequest<unknown>,
    { urlPattern, httpMethods }: any
  ): boolean {
    const httpTest =
      httpMethods?.length === 0 ||
      (httpMethods?.join() || "").indexOf(method?.toUpperCase()) > -1;

    const urlTest = urlPattern.test(url);

    return httpTest && urlTest;
  }

  /**
   * Intercept implementation that checks if the request url matches the excludedUrls.
   * If not, adds the Authorization header to the request if the user is logged in.
   *
   * @param req
   * @param next
   */
  public intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const { enableBearerInterceptor, excludedUrls } = environment.authConfig;
    if (!enableBearerInterceptor) {
      return next.handle(req);
    }

    const shallPass: boolean =
      !environment.shouldAddToken(req) ||
      excludedUrls.findIndex((item) => this.isUrlExcluded(req, item)) > -1;
    if (shallPass) {
      return next.handle(req);
    }

    // return from(this.conditionallyUpdateToken(req))
    // .pipe(mergeMap
    //   (isLoggedIn=>
    //   isLoggedIn? this.handleRequestWithTokenHeader(req, next)
    //   : next.handle(req)
    // ))

    return this.handleRequestWithTokenHeader(req, next);

    // return combineLatest([
    //   from(this.conditionallyUpdateToken(req)),
    //   from(this.authService.isLoggedIn())
    // ]).pipe(
    //   mergeMap(([_, isLoggedIn]) =>
    //     isLoggedIn
    //       ? this.handleRequestWithTokenHeader(req, next)
    //       : next.handle(req)
    //   )
    // );
  }

  /**
   * Adds the token of the current user to the Authorization header
   *
   * @param req
   * @param next
   */
  private handleRequestWithTokenHeader(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {

    let token = localStorage.getItem('access_token') as string;
    let kcReq=req;
    if (token) {
      kcReq=req.clone({setHeaders:{Authorization :this.bearerPrefix + ' ' + token}});
    }
    return next.handle(kcReq);
  }
}