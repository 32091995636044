import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

import { NgbModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';

import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { MatDialogModule } from '@angular/material/dialog';

import { NgChartsModule } from 'ng2-charts';

import { GlobalSpinnerComponent } from "./global-spinner/global-spinner.component";
import { GlobalSpinnerInterceptor } from './global-spinner/global-spinner.interceptor';
import { ToastsContainerComponent } from "./toasts-container/toasts-container.component";
import { NgCircleProgressModule } from 'ng-circle-progress';
import { EfDrawerComponent } from "./ef-drawer/ef-drawer.component";
import { AccordianComponent } from './accordian/accordian.component';
import { B2bDatepickerComponent } from './b2b-datepicker/b2b-datepicker.component';
import { B2bSelectComponent } from './b2b-select/b2b-select.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
    declarations: [
        GlobalSpinnerComponent,
        ToastsContainerComponent,
        EfDrawerComponent,
        AccordianComponent,
        B2bDatepickerComponent,
        B2bSelectComponent
    ],
    imports: [
        CommonModule,
        NgbModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatButtonModule,
        MatDividerModule,
        MatSnackBarModule,
        MatSelectModule,
        MatTableModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatSelectModule,
        MatChipsModule,
        MatDialogModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        ClipboardModule,
        NgbCollapseModule,
        NgCircleProgressModule.forRoot({
            // set defaults here
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: "#78C000",
            innerStrokeColor: "#C7E596",
            animationDuration: 300,
          }),
        NgChartsModule
    ],
    exports: [
        HttpClientModule,
        NgbModule,
        FormsModule,
        ReactiveFormsModule,
        MatMenuModule,
        MatButtonModule,
        MatDividerModule,
        MatSnackBarModule,
        MatSelectModule,
        MatTableModule,
        MatCheckboxModule,
        MatPaginatorModule,
        MatSelectModule,
        MatChipsModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        ClipboardModule,
        NgChartsModule,
        GlobalSpinnerComponent,
        ToastsContainerComponent,
        MatDialogModule,
        NgCircleProgressModule,
        EfDrawerComponent,
        AccordianComponent,
        B2bDatepickerComponent,
        B2bSelectComponent,
        MatProgressBarModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: GlobalSpinnerInterceptor, multi: true }
    ],
})
export class SharedModule { }