<div class="b2b-select-box" [ngClass]="{'ef-disabled': options.length == 0}">
    <!-- <input type="text" class="form-control" id="std-class" [matMenuTriggerData]="userMenuData"
        [matMenuTriggerFor]="menu" disabled> -->
    <div class="form-control" [matMenuTriggerData]="userMenuData" #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="menu" (click)="openMenu(menuTrigger)">{{selectedValue.label}}</div>
    <mat-menu #menu="matMenu" class="b2b-select-menu">
        <ng-template matMenuContent let-menuWidth="menuWidth">
            <div [style.width.px]="menuWidth">
                <button mat-menu-item *ngFor="let item of options" (click)="onSelectionChange(item)">{{item.label}}</button>
            </div>
        </ng-template>
    </mat-menu>
</div>