import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
      path: '',
      loadChildren: () => import('./modules/feature/post-login/post-login.module').then(m => m.PostLoginModule)
  },
  {
      path: 'login',
      loadChildren: () => import('./modules/feature/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'landing',
    loadChildren: () => import('./modules/feature/profile-landing/profile-landing.module').then(m => m.ProfileLandingModule),
    data: {
        userTypes: ['*']
    }
}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
