import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDateFormats } from '@angular/material/core';

export const MY_FORMATS: MatDateFormats = {
    parse: {
      dateInput: 'dddd, MMMM D',
    },
    display: {
      dateInput: 'dddd, MMMM D',
      monthYearLabel: 'MMM YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'MMMM YYYY',
    },
  };
  @Component({
    selector: 'app-b2b-datepicker',
    templateUrl: './b2b-datepicker.component.html',
    styleUrls: ['./b2b-datepicker.component.scss'],
    providers:[
        { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
{ provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
    
})
export class B2bDatepickerComponent {
    
    @Input() value: Date | undefined ;
    @Output() onDateChange = new EventEmitter();
    filterDate = (d: Date | null): boolean => {
        if (!d) {
          return false;
        }
        return d >= new Date();
      }
    dateChange(ev: any) {
        // console.log(ev.value);
        this.onDateChange.emit(ev.value);
    }
}
